:root {

    --body-backgound: #fff;

    --surface-ground:#ffffff;
    --text-color: #212633;
    --main-background: #182850;
    --main-background-hover: #0868d9;
    --color-with-background: #ffffff;
    --main-color: #fff;
    --white-background: #ffffff;
    --orange-background: #FF7C59;

    --header-background: #0C3399;

    --white-color: #ffffff;
    --white-color-06: rgba(255, 255, 255, 0.6);

    --ink-color:#505A71;
    --ink-color-5: #ECEFF8;
    --ink-color-6: #808AA6;
    --ink-color-20: #CAD2E6;
    --ink-color-40: #A9B3CC;
    --ink-color-80: #505A71;
    --ink-color-00: #F7FAFF;
    --p-menubar-500: 500;

    --bg-grey-02: rgba(255, 255, 255, 0.2);
    --grey-color: #80808078;

    --black-box-shadow-005: 0px 2px 2px rgb(0 0 0 / 5%);
    --black-box-shadow-01: 0px 4px 10px rgb(0 0 0 / 10%);
    --black-filter-shadow-005: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.05));
    --box-shadow-005: 0px 2px 2px rgba(0, 0, 0, 0.05);
    --border-grey: #D9DFF0;

    --bg-F3F9FF: #F3F9FF;
    --eaebed: #eaebed;
    --f1f1f1: #f1f1f1;
    --FFEFEB: #FFEFEB;
    --bdbdbd: #bdbdbd;

    --black-08: rgba(43, 43, 51, 0.8);
    --00CB51: #00CB51;
    --e5e5e5: #e5e5e5;
    --eff3ff: #eff3ff;
    --8a9cd3: #8a9cd3;
    --515663: #515663;
    --000000: #000000;
    --F3F9FF: #F3F9FF;
    --red: #ff0000;
    --B8C3E1: #B8C3E1;
    --E2E9FC: #E2E9FC;
    --D6DFF6: #D6DFF6;
    --2B2B33: #2B2B33;
    --c10000: #c10000;
    --f7941e: #f7941e;

    --525B73: #525B73;
    --F3F8FF: #F3F8FF;
    --color-text-normal: rgba(43, 47, 51, 0.4);
    --BDC4D8: #BDC4D8;

    --8E9ABB: #8E9ABB;
    --f68c1f: rgba(0, 82, 204, 1);
    --76809B: #76809B;

}

// .p-menubar .p-menubar-root-list > .p-menuitem.parent_active > a > .p-menuitem-text {
//     color: var(--f68c1f);
// }
// .p-menubar .p-menubar-root-list > .p-menuitem.parent_active > a{
//     background: #df4800 !important;
// }
// .p-menubar .p-menubar-root-list > .p-menuitem{
//     p-menubarsub{
//         &>ul>li{
//             &.active{
//                 a{
//                     background: #df4800 !important;
//                     .p-menuitem-text{
//                         color: #fff;
//                     }
//                 }
//             }
//             a{
//                 padding: 9px 10px 9px !important;
//                 background: #e67e00 !important;
//                 &:hover{
//                     background: #df4800 !important;
//                     .p-menuitem-text{
//                         color: #fff;
//                     }
//                 }
//                 &:focus{
//                     box-shadow: none;
//                 }
//                 .p-menuitem-text{
//                     font-size: 12px;
//                     color: #fff !important;
//                 }
//             }
//         }
//     }
// }

.app-body{
    .sidebarBody{
        background-color: var(--white-color);
        padding: 0px;
        border-radius: 0px;
        border: none;
        border-bottom: none !important;
        box-shadow: none;
    }
}

/* #p-menubar */
.p-menubar {
    .p-menubar-root-list{
        & > .p-menuitem {
            &> .p-menuitem-link{
                background: none !important;
                padding: 17px 12px 17px !important;
                &:focus{
                    box-shadow: none !important;
                }
                &:not(.p-disabled):hover {
                    .p-menuitem-text{
                        color: rgba(118, 128, 155, 1);
                    }
                    background: none !important;
                    box-shadow: none;
                    .p-submenu-icon,
                    .p-menuitem-icon{
                        color: rgba(0, 82, 204, 1) !important;
                    }
                }
                .p-menuitem-icon{
                    color: var(--white-color);
                    margin-left: 0.5rem;
                }
                &.p-menuitem-link-active{
                }
            }
            &.parent_active{
                &>.p-menuitem-link{
                    .p-submenu-icon{
                        color: var(--orange-background);
                    }
                    .p-menuitem-text{
                        color: rgba(0, 82, 204, 1) !important;
                    }
                }
            }
            &>.p-menuitem-link{
                .p-menuitem-text{
                    font-family: "SFProTextRegular";
                    white-space: nowrap !important ;
                    font-size: .875rem;
                    color: rgba(118, 128, 155, 1);
                    font-weight: 500;
                }
                .p-submenu-icon{
                    color: rgba(118, 128, 155, 1);
                    margin-left: 6px;
                }
            }
            &.parent_active{
                &>a{
                    &>.p-menuitem-text{
                        color: var(--main-color) !important;
                    }
                    .p-submenu-icon{
                        color: rgba(0, 82, 204, 1);
                    }
                }
            }
            &>a{
                &:hover{
                    &>.p-menuitem-text{
                        color: rgba(0, 82, 204, 1) !important;
                    }
                }
            }
            &.parent_active{
                &>a{
                    &>.p-menuitem-text{
                        color: var(--f68c1f);
                    }
                }
                .p-menuitem-icon{
                    color: var(--f68c1f);
                }
                .p-submenu-icon{
                    color: var(--f68c1f);
                }
                .p-submenu-list{
                    &>li.active{
                        &>a{
                            background: var(--f68c1f);
                            .p-menuitem-text{
                                color: var(--white-color);
                            }
                        }
                    }
                }
            }
            .p-submenu-list{
                &>li{
                    &>a{
                        .p-menuitem-icon{
                            margin-right: 4px;
                        }
                        .p-menuitem-text{
                            color: var(--000000);
                            white-space: nowrap;
                        }
                        padding: 12px 16px !important;
                        font-weight: 400;
                    }
                }
            }
            &.p-menuitem-active {
                &> .p-menuitem-link {
                    .p-submenu-icon{
                        color: #fff !important;
                    }
                }
            }
        }
        .p-submenu-list{
            background: var(--white-background);
            box-shadow: var(--black-box-shadow-01);
            margin-top: 10px !important;
            overflow: visible !important;
            z-index: 9999 !important;
            width: auto;
            max-height: 600px;
            overflow: auto !important;
            &::-webkit-scrollbar {
                width: 8px;
              }
            &::-webkit-scrollbar-track {
                border-radius: 99px;
                cursor: pointer;
                box-shadow: inset 0 0 2px #80808078;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                border-radius: 99px;
                    background:#c4c4c5;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #919192;
            }
            li{
                a{
                    &:focus,
                    &:hover{
                        background: rgba(0, 82, 204, 1);
                        span{
                            color: var(--white-color) !important;
                        }
                    }
                }
                &>.parent_active{
                    a{
                        background: rgba(0, 82, 204, 1);
                        span{
                            color: var(--white-color) !important;
                        }
                    }
                }
            }
            &>li{
                &>a{
                    color: var(--text-color);
                }
            }
        }
        &>.parent_active{
            &>.p-menuitem-link{
                span{
                    color: rgba(0, 82, 204, 1) !important;
                }
            }
        }
    }
    .p-menuitem-link{
        .p-menuitem-text{
            color: var(--ink-color);
            font-weight: var(--p-menubar-500);
        }
    }
    background: var(--white-background);
}



// @media screen and (max-width: 960px) {
//     .p-menubar .p-menubar-root-list{
//         background: #0052cc !important;
//     }
//     .main .sidebarBody{
//         padding: 7px 20px !important;
//     }
//     .p-menubar .p-menubar-button{
//         background: #e9ecef;
//     }
//     .p-menubar {
//         .p-menubar-root-list{
//             & > .p-menuitem {
//                 &> .p-menuitem-link{
//                     &:focus{
//                     }
//                     &:not(.p-disabled):hover {
//                         .p-menuitem-text{
//                             color: var(--white-color);
//                         }
//                         background: none !important;
//                         box-shadow: none;
//                         .p-submenu-icon,
//                         .p-menuitem-icon{
//                             color: var(--white-colo);
//                         }
//                     }
//                     .p-menuitem-icon{
//                         color: var(--white-color);
//                         margin-left: 0.5rem;
//                     }
//                 }
//                 &.parent_active{
//                     &>.p-menuitem-link{
//                         .p-submenu-icon{
//                             color: var(--white-color);
//                         }
//                     }
//                 }
//                 &>.p-menuitem-link{
//                     .p-menuitem-text{
//                         color: var(--white-color);
//                         white-space: nowrap !important
//                     }
//                     .p-submenu-icon{
//                         color: var(--white-color);
//                         margin-left: 6px;
//                     }
//                 }
//                 &.p-menuitem-active{
//                     &>a{
//                         &>.p-menuitem-text{
//                             color: var(--white-color) !important;
//                         }
//                         .p-submenu-icon{
//                             color: var(--white-color) !important;
//                         }
//                     }
//                 }
//                 &>a{
//                     &:hover{
//                         &>.p-menuitem-text{
//                             color: var(--white-color) !important;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

.more-right{
    button{
        background: rgb(100 100 100 / 36%) !important;
        border: none !important;
        &:hover{
            border: 1px solid #E2E6F2 !important;
        }
    }
    .p-tieredmenu{
        top: 100% !important;
        p-tieredmenusub{
            &>ul{
                &>li{
                    a{
                        .p-menuitem-text{
                            font-family: "SFProTextRegular";
                            white-space: nowrap !important;
                            color: rgba(0, 0, 0, 0.9) !important;
                            font-size: 0.875rem;
                            font-weight: 500;
                        }
                        &:hover{
                            .p-menuitem-text{
                                color: #1D4ED8 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1600px) {
    .more-right{
        .p-tieredmenu{
            p-tieredmenusub{
                p-tieredmenusub{
                    .p-submenu-list{
                        left: auto !important;
                        right: 100%;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 960px) {
    .p-menubar .p-menubar-root-list{
        width: auto;
    }
}


.main-menu{
    // background: #0B3A85;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 11;
    padding: 0px 8px;
    padding-left: 0px;
    .sidebarBody{
        // background: #0B3A85;
    }
}
