// @import "ag-grid-community/dist/styles/ag-grid.css";
// @import "ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "assets/layout/styles/layout/layout.scss";
@import "~@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
// @import "~@ag-grid-enterprise/all-modules/dist/styles/ag-theme-material.css";
@import "~@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";


@font-face {
    font-family: 'SFProTextMedium';
    src: url('assets/fonts/SFProText-Medium.woff2') format('woff2'),
        url('assets/fonts/SFProText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SFProTextRegular';
    src: url('assets/fonts/SFProText-Regular.woff2') format('woff2'),
        url('assets/fonts/SFProText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'SFProTextRegular';
    src: url('assets/fonts/SFProText-Medium.woff2') format('woff2'),
        url('assets/fonts/SFProText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

$fontMmedium: 'Arial','Apple SD Gothic Neo','Gulim';
$fontRegular: 'Arial','Apple SD Gothic Neo','Gulim';
$mainFont: 'SFProTextRegular';

$mainColor: #0B3A85;
$grey: #76809B;
$white: #fff;
$fontColor: #2B2F33;
$pink: #FF7ADA;
$yellow: #FFC01E;
$C-212633: #212633;
$C-99A2BC: #99A2BC;
$C-525B73: #525B73;
$C-F3F8FF: #F3F8FF;
$C-FF3B49: #FF3B49;
$C-E2E6F2: #E2E6F2;
$C-f68c1f: #f68c1f;
$orangeColor: rgba(0, 82, 204, 1);
.p-link,
.p-component{
    font-family: $mainFont
}
:root {
    --font-size: 13px;
    // --font-family: 'Open Sans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif';
    --font-family: $fontRegular
}
*{
    box-sizing: border-box;
}
html {
    font-size: 1rem;
    height: 100%;
    font-family: $fontRegular;
    line-height: 20px;
}
.main-grid{
    position: relative;
    padding-top: 0;
    .max-w-full {
        padding-top: 2px;
    }
    .tools{
        .p-button{
            padding: 10px
        }
    }
}
h6{
    margin-bottom: 10px;
}
p{
    margin: 0px;
}
.header{
    border-bottom: 1px solid rgba(226, 230, 242, 1);
    .p-toolbar{
        padding: 7px 6px !important;
        border: none;
        background-color: #fff;
        border-radius: 0;
        flex-wrap: nowrap;
        width: 100%;
        display: block;
    }
}
body {
    font-family: $mainFont;
    color: var(--text-color);
    padding: 0;
    margin: 0;
    min-height: calc(100% - 5px);
    height: calc( 100% - 5px );
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    font-size: var(--font-size);
    background: #EDF1FA;
    .p-component{
        font-size: var(--font-size);
    }
}

h1,
h2,
h3,
h4,
h5,
h6{
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 500;
    font-family: 'SFProTextRegular';
}

/* #p-breadcrumb */
.p-breadcrumb{
    background: none;
    box-shadow: none;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0px;
    li{
        font-size: 8px;
        font-weight: 500;
        a{
            .p-menuitem-text{
                font-size: 13px;
                font-weight: 400;
                color: #A3A9B9 !important;
                font-family: $fontRegular;
                white-space: nowrap;
            }
        }
        &.p-breadcrumb-chevron{
            color: rgba(43, 47, 51, 0.4) !important;
            padding-right: 5px;
            padding-left: 5px;
            margin-bottom: 4px;
        }
        &:last-child{
            a{
                .p-menuitem-text{
                    color: $C-212633 !important;
                }
            }
        }
    }
}

.legend-default{
    ul{
        padding: 0px;
        margin: 0px;
        display: flex;
        li{
            list-style: none;
            display: flex;
            align-items: center;
            margin-right: 12px;
            &:hover{
                cursor: pointer;
            }
            span{
                font-size: 12px;
                margin: 0px;
                color: rgba(51, 51, 51, 0.6);
                &:first-child{
                    width: 8px;
                    height: 8px;
                    border-radius: 75px;
                    margin-right: 5px;
                    display: inline-block;
                }
            }
        }
    }
    &.dou{
        ul{
            display: block;
            li{
                margin-bottom: 20px;
                span{
                    &:first-child{
                        width: 16px;
                        height: 16px;
                        min-width: 16px;
                        border-radius: 2px;
                        margin-right: 5px;
                        display: inline-block;
                    }
                }
            }
        }
    }
}

p-inputswitch{
    .p-inputswitch{
        height: 22px;
        width: 40px;
        .p-inputswitch-slider{
            &::before{
                width: 18px;
                height: 18px;
                margin-top: -9px;
            }
        }
        &.p-inputswitch-checked{
            .p-inputswitch-slider{
                background-color: $mainColor;
                &::before{
                    transform: translateX(15px);
                }
            }
        }
    }
}

button{
    background: none;
    border-radius: 4px;
    outline: none;
    box-shadow: none !important;
    border: 1px solid $C-F3F8FF;
    font-size: 13px;
    color: $C-F3F8FF;
    padding: 2px 5px;
    line-height: 1;
    font-family: $mainFont;
    font-weight: 500;
    cursor: pointer;
    background: $mainColor;
    border-radius: 4px !important;
    height: 32px;
    padding: 9px 7px !important;
    .pi{
        font-size: 13px !important;
        margin-right: 11px;
        font-weight: bold;
    }
    &.huy{
        color: #525B73 !important;
        border: none !important;
        background: #EDF1FA !important;
        font-weight: 500;
        font-size: 16px;
        span{
            color: #525B73 !important;
            font-family: $mainFont;
            font-weight: 500;
        }
        &:hover{
            background: #efefef !important;
        }
    }
}

button{
    &.p-button{
        background: rgba(0, 82, 204, 1);
        border-radius: 4px;
        height: 34px;
        padding: 9px 12px !important;
        border-color: transparent;
        min-width: 0px;
        .p-button-label{
            font-size: 13px;
            font-weight: 500;
        }
        &:enabled{
            &:hover{
                background: rgba(0, 82, 204, 1);
                color: $white;
                border-color: transparent;
            }
        }
        svg {
            height: 14px;
            width: auto;
        }
        &.box-icon{
            border: 1px solid #E2E6F2;
            border-radius: 4px !important;
            background: none !important;
            width: auto;
            &:hover{
                border: 1px solid #E2E6F2;
            }
        }
    }
    &.btn-option{
        border: 1px solid $C-E2E6F2 !important;
        background: none;
        min-width: 0px;
        svg{
            path{
                fill: $grey;
            }
        }
        &:hover{
            svg{
                path{
                    fill: $white
                }
            }
        }
    }
    &.btn-trans{
        border: 1px solid rgba(226, 230, 242, 1) !important;
        background: none;
        color: $grey;
        &:hover{
            svg{
                path{
                    fill: $white
                }
            }
            color: $grey;
        }
    }
}
.flex,
.d-flex,
.grid{
    display: flex;
    &.d-right{
        justify-content: right;
    }
    &.bet{
        justify-content: space-between;
    }
    &.end{
        justify-content: flex-end;
    }
    &.middle{
        align-items: center;
    }
    &.center{
        justify-content: center;
    }
    &.bottom{
        align-items: flex-end;
    }
    &.wrap{
        flex-wrap: wrap;
    }
}

.pb0{
    padding-bottom: 0px;
}
.pd-0,
.pd0{
    padding: 0 !important;
}
.gap-16{
    gap: 16px;
}
.gap-12{
    gap: 12px;
}
.gap4{
    gap: 4px
}
.gap35{
    gap: 35px;
}
.gap-10{
    gap: 10px;
}
.gap6{
    gap: 6px;
}
.gap20{
    gap: 20px;
}
.gap12{
    gap: 12px;
}

.gap8{
    gap: 8px;
}

.bread-crumb{
    border-bottom: 1px solid #E2E6F2;
    background: #fff;
    padding: 11px 20px;
    &>div{
        min-height: 34px;
    }
    .p-breadcrumb{
        ul{
            li{
                &:first-child{
                    padding-left: 0px;
                }
            }
        }
    }
}
.number-info{
    width: 38px;
    height: 24px;
    border-radius: 4px;
    background: #FFF3F0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px !important;
    &.reduce{
        color: #FF7C59 !important;
    }
    &.inc{
        color: #34C759 !important;
    }
}

.grid-default{
    position: relative;
    border-radius: 0px 0px 4px 4px;
    .ag-header{
        border-bottom: none;
    }
    .ag-root-wrapper{
        border-color: transparent;
        border-radius: 3px;
        .ag-header-row{
            .ag-cell{
                background: $white;
            }
         .ag-header-cell{
             min-width: 58px;
             .ag-header-cell-label{
                 .ag-header-cell-text{
                    // color: $C-212633;
                    // font-size: 13.5px;
                    // font-weight: 500;
                    // white-space: normal !important;
                 }
             }
             &:first-child{
                // border-radius: 4px 0px 0px 0px;
             }
             &:last-child{
                // border-radius: 0px 4px 0px 0px;
                // border-bottom: 0.5px solid #d4d4d4 !important;
             }
         }
        }
    }

}

.paginator{
    color: $grey;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: #FFFFFF;
    .p-paginator{
        padding: 0px;
        .p-paginator-first, .p-paginator-last {
            display: none !important;
        }


    }
  .all_1000000000 {
    .p-dropdown-label::before {
      content: "All";
      visibility: visible;
    }
    .p-dropdown-label::after {
      content: "";
    }
    .p-dropdown-label {
      visibility: hidden;
      width: 35px;
      display: flex;
    }
  }
    .p-paginator-rpp-options{
        height: 24px;
        background: rgba(0, 82, 204, 1);
        border-radius: 3px;
        border: none;
        padding: 0px !important;
        .p-dropdown-label{
            padding: 0px 8px;
            line-height: 24px;
            font-size: 14px;
            color: $white;
        }
        .p-dropdown-trigger-icon{
            color: $white;
        }
        .p-dropdown-trigger{
            width:2rem;
        }
    }
    button{
        border-radius: 2px;
        color: $grey !important;
        font-size: 14px;
        width: 24px;
        height: 24px !important;
        display: inline-block;
        text-align: center;
        margin: 0px !important;
        min-width: 0px !important;
        font-weight: 500;
        padding: 0px !important;
        .p-paginator-icon{
            line-height: 24px;
            margin-right: 0px;
        }
        &.p-highlight{
            background: rgba(0, 82, 204, 1) !important;
            color: $white !important;
        }
        &.p-paginator-first,
        &.p-paginator-prev,
        &.p-paginator-last,
        &.p-paginator-next{
            .p-paginator-icon{
                color: $C-525B73
            }
        }

    }
}

app-form-detail{
    // padding-left: 12px;
    // padding-right: 12px;
    &>form{
        &.Form{
            .buttons-detail{
                position: absolute;
                right: 15px;
                top: 8px;
                font-size: 16px;
            }
        }
    }
    .field{
        margin-bottom: 0px;
        &.avatar{
            p-image{
                img{
                    height: 100%;
                    width: auto;
                }
            }
        }
    }
    formly-field{
        &[class*=col]{
            padding-bottom: 0px;
        }
        &.col-12{
            // padding: 0px;
        }
        .card-header{
            color: $mainColor;
            margin-bottom: 16px;
            position: relative;
            padding-bottom: 5px;
            font-weight: 700;
            .line-bottom{
                width: 32px;
                height: 4px;
                background: #0b3a85b8;
                position:absolute;
                bottom:0;
                left:0;
            }
        }

        &>formly-wrapper-panel{
            &>.card{
                background: $white;
                // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
                // padding: 20px;
                // margin-bottom: 18px;
            }
        }
    }


    .b-r{
        border-right: 1px solid #ececec;
    }
}
.p-overlaypanel{
    app-form-detail{
        &>form{
            .buttons-detail{
                justify-content: end;
                padding-top: 12px;
                border-top: 1px solid rgba(43, 47, 51, 0.05);
                margin-top: 8px;
            }
        }
    }
    // z-index: 1 !important;
}
p-tabview{
    .p-tabview-nav-container{
        .p-tabview-nav-content{
            margin-bottom: 12px;
            .p-tabview-nav{
                border-bottom: 1px solid $C-E2E6F2;
                padding-top: 0px;
                &>li{
                    a{
                        padding: 8px 16px;
                        border-radius: 0;
                        &:hover,
                        &:focus{
                            background: none !important;
                            outline: none;
                            box-shadow: none !important;
                        }
                        &:hover{
                            span{
                                color: $orangeColor
                            }
                        }
                        .uni-icon{
                            margin-right: 5px;
                        }
                        span{
                            font-weight: 500;
                            color: #212633;
                            white-space: nowrap;
                        }
                        @media screen and (max-width: 1440px) {
                            padding: 8px 12px;
                        }
                    }
                    span{
                        &.p-tabview-title{
                          font-weight: 400;
                          line-height: 20px;
                          font-size: 14px;
                          color: #212633;
                        }
                    }
                    &.p-tabview-ink-bar{
                        background: $mainColor;
                        bottom: 11px;
                        display: none;
                    }
                    &.p-highlight{
                        a{
                            border-color: rgba(0, 82, 204, 1);
                            span{
                                color: rgba(0, 82, 204, 1);
                            }
                        }
                    }
                }
            }
        }
    }
    .p-tabview-panels{
        padding: 0px 12px !important;
        background: none !important;
    }
    section{
        padding-left: 0px;
        padding-right: 0px;
    }
    .p-tabview-nav-btn{
        height: 36px;
        box-shadow: 1px 1px 8px #ccc !important;
        border-radius: 0px !important;
        display: block;
        text-align: center;
        border: 1px solid #e9e9e9;
        &:hover{
            background: #f1f1f1 !important;
        }
        .pi{
            color: #212633;
            margin-right: 0px;
        }
    }
    .p-tabview-nav-prev{

    }
    .tab-ver{
        display: flex;
        .p-tabview-nav-content{
            margin-bottom: 0px;
        }
        &>.p-tabview-nav-container{
            .p-tabview-nav{
                display: block;
                padding: 12px 0px;
                overflow: auto;
                &::-webkit-scrollbar {
                    width: 8px;
                  }
                  &::-webkit-scrollbar-track {
                    border-radius: 99px;
                    cursor: pointer;
                    box-shadow: inset 0 0 2px #80808078;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    border-radius: 99px;
                        background:#c4c4c5;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #919192;
                }
                li{
                    counter-increment: custom;
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding-left: 10px;
                    a{
                        margin-bottom: 0px !important;
                        border-bottom: 1px solid #f5f5f5 !important;
                        padding-left: 3px;
                        span{
                            white-space: unset !important;
                            line-height: 1;
                        }
                        .uni-icon{
                            &::after{
                                background: none;
                            }
                        }
                    }
                    &[role="presentation"]{
                        &:last-child{
                            a{
                                border-bottom: none !important;
                            }
                        }
                    }
                    &:before{
                        content: counter(custom) ". ";
                        // position: absolute;
                        left: 13px;
                        z-index: 1;
                        top: 8px;
                        font-weight: 500;
                    }
                    &.p-highlight{
                        &:before{
                            color: $orangeColor;
                        }
                    }
                    &:hover{
                        &:before{
                            color: $orangeColor;
                        }
                    }
                }
            }
            width: 15%;
            padding-left: 0px;
        }
        &>.p-tabview-panels{
            width: 85%;
        }
        @media screen and (max-width: 1600px) {
            &>.p-tabview-nav-container{
                width: 18%;
                padding-left: 12px;
            }
            &>.p-tabview-panels{
                width: 82%;
            }
        }
        @media screen and (max-width: 1366px) {
            &>.p-tabview-nav-container{
                width: 22%;
                padding-left: 12px;
            }
            &>.p-tabview-panels{
                width: 78%;
            }
        }
    }
    .no-icon{
        .p-tabview-nav{
            li{
                a{
                    i{
                        display: none;
                    }
                }
            }
        }
    }
    .tab-grid{
        .p-tabview-panels{
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }
}
ul{
    padding: 0px;
    margin: 0px;
    li{
        list-style: none;
    }
}

section{
    padding: 12px 12px;
}

.border-right {
    border-right: 1px solid rgb(221, 226, 235) !important;
}

.ag-root-wrapper{
    .ag-body-viewport{
        .ag-row-odd{
            // background: #fcfcfc;
        }
    }
    .ag-cell{
        background: $white;
      &.ag-cell-inline-editing{
        height: auto;
        app-picker-cell-editor{
            .p-calendar{
              input{
                padding: 8px 5px;
                border-radius: 0px;
                border: none;
              }
            }
        }
      }
    }
    .ag-header-row{

        // &:first-child{
        //     border-bottom: 1px solid #babfc7;
        // }
        .ag-header-cell-resize::after {
            content: "";
            position: absolute;
            z-index: 1;
            display: block;
            left: calc(50% - 1px);
            width: 2px;
            height: 30%;
            top: calc(50% - 15%);
            background-color: rgba(186, 191, 199, 0.5);
            background-color: rgba(186, 191, 199, 0.5);
        }
    }
  .ag-cell-not-inline-editing{
    .ag-cell-value{
      width: 100%;
    }
  }
  .ag-cell-wrapper{
    .p-dropdown{
      border: none;
      background-color: var(--orange-400) !important;
      height: 36px;
      border-radius: 2px;
      padding: 0px 5px;
      align-items: center;
      width: 100%;
      .p-dropdown-label{
        padding: 0px;
      }
      .p-dropdown-trigger{
        width: auto;
        padding-left: 10px;
        .pi{
          font-size: 12px;
          color: #fff;
        }
      }
    }
    p-autocomplete{
      .p-autocomplete{
        height: 36px;
        border-radius: 2px;
        margin: auto;
        align-items: center;
        justify-content: center;
        background-color: var(--orange-400) !important;
        input{
          width: 100%;
          background-color: var(--orange-400) !important;
          color: #f7f7f7;
          border: 1px solid var(--orange-400) !important;
        }
      }
    }
    p-calendar{
      .p-calendar{
        height: 36px;
        width: 100%;
        input{
          width: 100%;
          background-color: var(--orange-400) !important;
          color: #f7f7f7;
          border: 1px solid var(--orange-400) !important;
          border-radius: 0;
        }
      }
    }
    p-fileupload{
      .p-fileupload{
        height: 36px;
        width: 100%;

      }
    }
    p-calendar .p-calendar,
    p-autocomplete .p-autocomplete{
      input{
        &::-webkit-input-placeholder {
          color: #fff;
        }
        &::-moz-placeholder {
          color: #fff;
        }
        &:-ms-input-placeholder {
          color: #fff;
        }
        &:-moz-placeholder {
          color: #fff;
        }
      }
    }
  }
}
// field
.p-field,
.fields{
    .p-disabled{
        opacity: 1;
    }
    margin-bottom: 8px;
    padding-bottom: 0px !important;
    .p-error{
        // bottom: -18px !important;
        color: red;
    }
    .p-dropdown-trigger{
        padding-left: 10px;
        padding-right: 10px;
    }
    label{
        color: $C-212633;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 4px;
        font-weight: 500;
        display: block;
        transition: all 0.3s;
        font-family: 'SFProTextRegular';
    }
    &.valid{
        label{
            top: 0px;
            transition: all 0.3s;
        }
    }

    input{
        display: block;
        font-size: 14px !important;
        color: $fontColor !important;
        width: 100% !important;
        border: 1px solid $C-E2E6F2;
        border-radius: 4px !important;
        height: 40px;
        padding: 10px !important;
        font-family: 'SFProTextRegular';
        font-weight: 400;
        &:focus-visible{
            outline: none;
        }
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $C-99A2BC;
            font-size: 13px;
            font-family: 'SFProTextRegular';
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: $C-99A2BC;
            font-size: 13px;
            font-family: 'SFProTextRegular';
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: $C-99A2BC;
            font-size: 13px;
            font-family: 'SFProTextRegular';
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: $C-99A2BC;
            font-size: 13px;
            font-family: 'SFProTextRegular';
        }
        &:disabled{
            background: #f1f1f1 !important;
        }
    }
    .field{
        input{
            &:disabled{
                background: #f1f1f1 !important;
                color: #99A2BC !important;
            }
        }
    }
    &.warm{
        font-family: 'SFProTextRegular';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        label{
            color: #525B73;
            font-weight: 500;
            min-width: 45px;
        }
        ul{
            padding-left: 20px;
            margin-top: 0px;
            li{
                color: $grey;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                font-family: $fontRegular;
                margin-bottom: 4px;
            }
        }
    }
    position: relative;
    .icon-eye{
        position: absolute;
        right: 12px;
        top: 40px;
        &:hover{
            cursor: pointer;
        }
    }
    &.err{
        input{
            border: 1px solid #FF3B49;
        }
    }
    .icon-search{
        position: absolute;
        left: 15px;
        top: 39px;
    }
    &.saerch{
        input{
            padding-left: 44px;
        }
    }
    &.date-from-to{
        input{
            border: none;
        }
        .input{
            display: flex;
            border: 1px solid $C-E2E6F2;
            border-radius: 8px;
            position: relative;
            background: #fff;
            input{
                &:focus{
                    box-shadow: none
                }
            }
            &>div{
                width: 50%;
            }
        }
        .to{
            position: absolute;
            left: calc(50% - 10px);
            transform: translate(-50%, -50%);
            top: 50%;
            z-index: 2;
        }
        .to-date{
            position: relative;
            svg{
                position: absolute;
                right: 14px;
                top: 14px;
            }
        }
    }
    .p-multiselect,
    .p-dropdown{
        width: 100% !important;
        border: 1px solid $C-E2E6F2;
        border-radius: 4px !important;
        height: 40px;
        &:hover{
            border-color: $C-E2E6F2;
        }
        .p-dropdown-label{
            color: $C-212633;
            font-size: 14px;
            padding-left: 12px;
            padding-right: 12px;
            display: flex;
            align-items: center;
            font-family: 'SFProTextRegular';
            font-weight: 400;
            &.p-placeholder{
                color: $C-99A2BC;
            }
        }
        .p-dropdown-trigger-icon{
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.209209 0.21967C-0.0697365 0.512563 -0.0697365 0.987437 0.209209 1.28033L4.49492 5.78033C4.77387 6.07322 5.22613 6.07322 5.50508 5.78033L9.79079 1.28033C10.0697 0.987437 10.0697 0.512564 9.79079 0.21967C9.51184 -0.0732229 9.05958 -0.073223 8.78064 0.21967L5 4.18934L1.21936 0.21967C0.940416 -0.0732233 0.488155 -0.0732233 0.209209 0.21967Z' fill='%2376809B'/%3E%3C/svg%3E%0A");
            width: 10px;
            height: 6px;
            &::before{
                display: none;
            }
        }
        &.p-disabled{
            background-color: #f1f1f1 !important;
            .p-dropdown-label{
                color: $C-99A2BC
            }
        }
    }
    app-nz-multiselect{
        .field{
            position: relative;
            padding-right: 50px;
            .p-button{
                position: absolute;
                right: 0px;
                top: 0px;
                height: 39px;
                width: 50px;
                border-radius: 0px 4px 4px 0px !important;
            }
        }
    }
    .p-multiselect{
        .p-multiselect-label{
            color: $C-212633;
            font-size: 14px;
            padding-left: 12px;
            padding-right: 12px;
            display: flex;
            align-items: center;
            font-family: 'SFProTextRegular';
            font-weight: 400;
            height: 100%;
            &.p-placeholder{
                color: $C-99A2BC;
            }
        }
    }
    &.field-search{
        input{
            padding: 24px 0px 0px;
            border: none;
            text-transform: uppercase;
        }
    }
    .p-calendar{
        border: none !important;
    }
    .p-inputnumber,
    .p-calendar,
    .p-autocomplete{
        width: 100%;
        display: block;
    }
    .p-checkbox{
        height: 40px;
    }
    p-inputnumber{
        display: block;
    }

    &.sm{
        .p-dropdown{
            height: 32px;
            .p-dropdown-label{
                padding: 5px 12px;
            }
        }
    }
    &.search{
        position: relative;
        input{
            padding-left: 45px !important;
            max-width: 354px;
        }
        svg{
            position: absolute;
            left: 16px;
            top: 8px;
            cursor: pointer;
            height: 18px;
            width: 17px;
        }
        .remove-text{
            // position: absolute;
            // left: calc(100% - 90px);
            // top: 3px;
            svg{
                position: static;
                width: 13px;
                margin-left: -25px;
                margin-top: 7px;
                position: relative;
                z-index: 5;
                top: auto;
                left: auto;
            }
        }
        button{
            // position: absolute;
            // right: 0px;
            // top: 0px;
            // min-width: 32px;
            // text-align: center;
            // display: flex;
            // align-items: center;
            overflow: visible;
            svg{
                position: static;
                width: 16px;
            }
            .dot{
                position: absolute;
                right: -3px;
                top: -3px;
                width: 8px;
                height: 8px;
                display: block;
                border-radius: 100%;
                background-color: $C-FF3B49;
                z-index: 1;
            }
        }
        &.no-advanced{
            .remove-text{
                left: calc(100% - 40px);
            }
        }
    }
    .nz-link-url{
        svg{
            position: absolute;
            bottom: 16px;
            right: 10px;
            cursor: pointer;
            z-index: 1;
        }
    }
    textarea{
        font-size: 14px !important;
        color: $fontColor !important;
        width: 100% !important;
        border: 1px solid $C-E2E6F2;
        border-radius: 4px !important;
        padding: 10px !important;
        font-family: 'SFProTextRegular';
        font-weight: 400;
    }
    p-calendar{
        .p-timepicker{
            .pi{
                margin-right: 0px;
            }
        }
    }
}

.bread-crumb{
    .fields{
        .p-dropdown,
        input{
            height: 34px;
        }

    }
}
p-tieredmenusub{
    ul{
        background: #fff;
        box-shadow: 1px 1px 4px #ccc;
        border-radius: 3px;
        padding: 6px !important;
        li{
            margin-bottom: 10px;
            .p-menuitem-icon{
                margin-right: 6px;
                color: #222;
            }
            a{
                color: #222;
            }
        }
    }
}


.datepicker-default{
    background-color: #fff;
    .p-datepicker-calendar{
        td{
            .p-highlight{
                background-color: $mainColor;
                color: $white
            }
        }
    }
}
.p-overlaypanel {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    padding: 15px;
    .p-overlaypanel-content{
        padding: 0px;
    }
    .p-overlaypanel-close {
        background: #3B82F6;
        color: #ffffff;
        width: 2rem;
        height: 2rem;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 50%;
        position: absolute;
        top: -1rem;
        right: -1rem;
      }
      &:after {
        border: solid transparent;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
        border-width: 10px;
        margin-left: -10px;
      }
      &:before {
        border: solid transparent;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #f2f2f2;
        border-width: 8px;
        margin-left: -8px;
      }
      .card{
        margin-bottom: 0px;
        box-shadow: none;
        padding: 0px;
      }
  }

  .profile{
    .p-avatar{
        width: 32px;
        height: 32px;
        margin-top: 1px;
    }
    .card{
        justify-content: center;
        line-height: 1;
        gap: 8px;
    }
    p-avatar{
        img{
            border-radius: 2px;
        }
    }
    .name{
        color: #212633;
        font-size: 14px;
        text-transform: uppercase;
        cursor: pointer;
        font-weight: 500;
    }
    .item{
        width: 32px;
        height: 32px;
        display: flex;
        background: rgb(100 100 100 / 10%);
        border-radius: 2px;
        text-align: center;
        align-items: center;
        justify-content: center;
        &:hover{
            cursor: pointer;
            svg{
                path{
                    fill: $mainColor;
                }
            }
        }
    }
  }

  .p-splitbutton{
    border-radius: 4px;
    background-color: $mainColor;
    button{
        border: none;
        .pi-chevron-down{
            margin-right: 0px;
        }
    }
    .p-splitbutton-menubutton{
        width: auto;
        padding-left: 8px;
        padding-right: 8px;
        &:focus{
            outline: none;
            box-shadow: none
        }
    }
      .p-splitbutton-defaultbutton{
        padding-left: 8px;
        padding-right: 8px;
        &:after{
            content: '';
            display: block;
            height: 20px;
            width: 1px;
            background-color: $white;
            position: absolute;
            right: 0px;
            top: 6px;
            opacity: 0.6;
        }
    }
  }
.page-detail{
    section{
        padding: 0px 12px;
        .card{
            margin-bottom: 4px;
        }
        &.bread-crumb{
            padding: 10px 20px;
        }
    }
}

app-form-detail{
    formly-field{
        formly-group{
            &.grid{
                &>[class*=col]{
                    background: #fff;
                }
            }
            &[class*=col]{
                background: #fff;
            }
            &.grid{
                margin-top: 0px;
                formly-field{
                    &[class*=col]{
                        padding-top: 0px;
                        &.pt-1{
                            padding-top: 15px;
                        }
                    }
                }
            }
            formly-field{
                &:first-child{
                    // padding-top: 4px;
                }

                .padding-0{
                    padding:0;
                    .card{
                        box-shadow:none;
                        margin-bottom:0;
                    }
                    formly-wrapper-panel{
                        .card{
                            padding-top: 0px;
                            padding-bottom: 0px;
                        }
                    }
                }
                .boder-bt{
                    background-color: unset !important;
                    .card{
                        box-shadow:0px 4px 8px rgba(0, 0, 0, 0.05);
                        margin-bottom: 18px;
                    }
                }
                .boder-right{
                    background-color: unset !important;
                    .card{
                        box-shadow:0px 4px 8px rgba(0, 0, 0, 0.05);
                        margin-right: 15px;
                    }
                }
                .boder-left{
                    background-color: unset !important;
                    .card{
                        // box-shadow:0px 4px 8px rgba(0, 0, 0, 0.05);
                        margin-left: 18px;
                    }
                }
                .boder-top{
                    margin-top: 18px;
                    .card{
                        // box-shadow:0px 4px 8px rgba(0, 0, 0, 0.05);
                    }
                }
                .vertical {
                    background-color: #ffffff;
                    .card {
                        height: 100%;
                        margin-bottom: 18px !important;

                    }
                    .p-image{
                        img{
                            @media(max-width: 1440px){
                                width: 220px;
                                object-fit: fill;
                            }
                            @media (max-width: 1280px) {
                                width: 200px;
                            }
                            @media (max-width: 1024px){
                                width:149px;
                                height:200px;
                            }
                        }
                    }
                }
            }
        }
    }
    &>form{
        padding-left: 9px;
        padding-right: 9px;
        &.ng-invalid{
            &.ng-touched{
                .p-field,
                .fields{
                    margin-bottom: 15px;
                    .field{
                        line-height: 1;
                    }
                }
            }
        }
    }

}
span.ng-invalid{
    margin-top: 5px;
    display: block;
}
.isVisibility {
    visibility: hidden;
}
p-dialog{
    button{
        .p-dialog-header-close-icon{
            margin-right: 0px;
        }
    }
    .p-dialog-content{
        .main-grid{
            position: static;
            .buttons-detail{
                right: 113px;
                top: 18px;
            }
        }
        app-form-detail{
            form{
                .pi-cog{
                    top: 20px !important;
                    right: 65px !important;
                }
            }
        }
    }
}
    .p-sidebar{
        .p-sidebar-header{
            color: #2B2F33;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            border-bottom: 1px solid #EDF1FA;
            padding: 16px 30px;
            font-family: 'SFProTextRegular';
            margin-bottom: 15px;
        }
        app-form-detail {
            &> form.Form {
                .buttons-detail{
                    position: fixed;
                    bottom: 16px;
                    top: auto;
                    right: 40px;
                    justify-content: end;
                }
            }
            form{
                .pi-cog{
                    position: fixed !important;
                    right: 20px !important;
                    bottom: 16px !important;
                    top: auto !important
                }
            }
        }
        .p-sidebar-close-icon{
            margin-right: 0px;
        }
        .p-sidebar-footer{
            min-height: 73px;
            border-top: 1px solid #EDF1FA;
        }
        .p-tabview-panels{
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
        section{
            padding-left: 0px;
            padding-right: 0px;
        }
        // z-index: 2122 !important;
    }
// .p-sidebar-mask{
//     z-index: 2121 !important;
// }
.box{
    background: #FFFFFF;
    border-radius: 8px;
    padding: 16px;
    .title{
        margin-bottom: 16px;
    }
    h3{
        margin-bottom: 8px;
    }
    p{
        color: #76809B;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }
}
.box-icon{
    border-radius: 4px;
    border: 1px solid;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.border-grey{
        border-color: #E2E6F2;
    }
}

.list-warm{
    .head{
        color: #212633;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 24px;

    }

    .content-t{
        max-height: 270px;
        overflow: auto;
        .grid{
            div{
                padding-top: 0px !important;
                padding-bottom: 0px !important    ;
            }
        }
    }
}

p-accordion{
    .p-accordion {
        overflow-y: auto;
        .p-accordion-header{
            a{
                background: #fff !important;
            }
            .title-section{
                margin-bottom: 0px;
            }
        }
        &::-webkit-scrollbar {
            width: 8px;
          }
          &::-webkit-scrollbar-track {
            border-radius: 99px;
            cursor: pointer;
            box-shadow: inset 0 0 2px #80808078;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            border-radius: 99px;
                background:#c4c4c5;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #919192;
        }
    }
    p-accordiontab{
        .p-accordion-tab{
            margin-bottom: 8px !important;
        }
        .p-accordion-header{
            .p-accordion-header-link{
                border-radius: 4px !important;
                border: none !important;
                flex-direction: row-reverse;
                justify-content: space-between;
                padding: 12px 16px !important;
                border-bottom: 1px solid #ccc !important;
                border-bottom-left-radius: 0px !important;
                border-bottom-right-radius: 0px !important;
                &:focus{
                    box-shadow: none !important;
                }
                .p-accordion-header-text{
                    color: #525B73;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                }
                .p-accordion-toggle-icon{
                    color: #525B73;
                }
            }
            &.p-highlight{
                .p-accordion-header-text{
                    color: $mainColor;
                }
                .p-accordion-toggle-icon{
                    color: $mainColor;
                }
            }
        }
        .p-accordion-content{
            .filter-search{
                input{
                    height: 34px;
                }
            }
            padding: 0px !important;
            border: none !important;
            div{
                color: #212633;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
            }
            .item{
                padding: 10px 24px;
                border-bottom: 1px solid #EDF1FA;
                margin: 0px;
                &:last-child{
                    border-bottom: none;
                }
                &>div{
                    padding-top: 0px;
                    padding-bottom: 0px;
                }
            }
        }
    }
}
ag-grid-angular{
    .ag-root-wrapper{
        .ag-root{
            .ag-pinned-right-cols-container{
                background: rgba(0, 0, 0, 0.25);
                box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.25)
            }
            .ag-header{
                background: #407ED9;
                .ag-header-cell-label {
                    .ag-header-cell-text{
                        font-family: "SFProTextRegular";
                        font-size: 14px;
                        color: #fff;
                        font-weight: 500;
                    }
                }
                .ag-floating-filter{
                    .ag-input-wrapper{
                        input{
                            border: 1px solid #E2E6F2;
                            border-radius: 4px;
                        }
                    }
                    .ag-floating-filter-button-button{
                        padding: 0px !important;
                    }
                }
            }
            .ag-row{
                border-color: #f5f5f5;
                &.ag-row-odd {
                    .ag-cell{
                        background: rgba(243, 248, 255, 1);
                        border-color: #EDF1FA;
                    }
                }
              .ag-cell-wrapper{
                .ag-group-value{
                  font-family: 'SFProTextRegular';
                  font-weight: 400;
                  font-size: 14px;
                  color: #212633;
                }
              }
            }
            .ag-cell{
                .ag-cell-wrapper{
                    .ag-cell-value{
                        font-family: 'SFProTextRegular';
                        font-weight: 400;
                        font-size: 14px;
                        color: rgba(33, 38, 51, 1);
                    }
                }
            }
            .ag-row-focus{
                .ag-cell{
                    background: #fff !important;
                    &.ag-cell-range-selected{
                        background: #fff !important;
                        height: 100% !important;
                        // border: 2px solid #0B3A85 !important;
                    }
                }

            }
            .ag-cell-range-selected{
                background: #fff !important;
            }
            .ag-cell{
                &.ag-cell-range-selected{
                    background: #fff !important;
                }
            }
        }
        .ag-row-hover{
            .ag-cell {
                background: #eee;
            }
        }
    }
}
.popup-setting{
    .ag-body-horizontal-scroll{
        position: fixed;
        bottom: 0px;
        left: 0px;
    }

}
.ag-root-wrapper{
    .ag-row-focus{
        .ag-cell{
            border-top: 1px solid #9abdf5 !important;
                    border-bottom: 1px solid #9abdf5 !important;
            border-left: transparent;
            // background-color: rgba(0, 145, 234, 0.2);
        }
    }

  .text-red-500{
      .ag-cell-value {
        color: var(--red-500) !important;
      }
  }
}
.p-dialog{
    app-form-detail{
        span{
            &.setting{
                top: 20px !important;
                right: 65px !important;
            }
        }
        .buttons-detail{
            right: 110px !important;
            top: 18px !important;
        }
    }
    .p-dialog-header-icons{
        .p-dialog-header-close-icon{
            margin-right: 0px;
        }
    }
}
input[hidden]{
    display: none !important;
}


div{
  &.choose-language{
    z-index: 9999 !important;
  }
}
.choose-language{
    padding: 0px !important;
    min-width: 180px;
    border-radius: 3px !important;
    ul li,
    h3{
        padding: 8px 15px;
    }
    ul{
        li{
            font-weight: 500;
            &:hover{
                cursor: pointer;
            }
            &:hover,
            &.active{
                background: #f2f3f7;
            }
            .checked{
                position: absolute;
                right: 10px;
            }
        }
    }
  }

.dots-menu-content{
    background: #FFFFFF !important;
    box-shadow: 0px 1.64307px 6px rgba(94, 136, 179, 0.2) !important;
    border-radius: 4px;
    padding: 0px;
    min-width: 205px;
    ul{
        padding: 0px !important;
        box-shadow: none;
        border-radius: 0;
        li{
            a{
                padding: 8px 20px !important;
                .p-menuitem-text{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #212633 !important;
                    font-family: $mainFont;
                }
            }
        }
    }
}
.p-component-overlay{
    app-config-grid-table-form{
        .btns{
            right: 107px !important;
            top: 23px !important
        }
    }
    .p-dialog-header-icons{
        .pi{
            margin-right: 0px;
        }
    }
    .popup-config{
        app-form-detail{
            .buttons-detail{
                right: 63px !important;
                top: 24px !important;
            }
        }
    }
}

.form-avatar{
    .avatar{
        position: relative;
    }
    app-nz-avatar{
        .p-image{
            position: relative;
            display: block;
            &:after{
                content: '';
                padding-top: 100%;
                display: block;
            }
            &>img{
                position: absolute;
                object-position: center;
                height: 100% !important;
                top: 0px;
                width: calc(100% - 15px) !important;
                object-fit: cover;
                border-radius: 3px;
                left: 0;
                // width: 15em !important;
                // height: 19em !important;
                // object-position: 25% 0;
                // object-fit: cover;
            }
        }
    }
}
//.p-sidebar-mask{
//    z-index: 1102 !important;
//}
//p-sidebar{
//    &>.p-sidebar{
//        z-index: 1103 !important;
//        p-sidebar{
//            &>.p-sidebar{
//                z-index: 1104 !important;
//                p-sidebar{
//                    &>.p-sidebar{
//                        z-index: 1105 !important;
//                    }
//                }
//            }
//        }
//    }
//}

.title-section{
  position: relative;
  color: #0B3A85;
  margin-bottom: 16px;
  position: relative;
  padding-bottom: 5px;
  font-weight: 700;
  &:after{
    content: '';
    top: 100%;
    width: 32px;
    height: 4px;
    background: rgba(11, 58, 133, 0.7215686275);
    position: absolute;
    left: 0;
  }
}
.p-sidebar-mask{
  z-index: 11 !important;
}
.p-sidebar{
  z-index: 12 !important;
}
.p-dialog-mask{
  z-index: 13 !important;
  .p-dialog{
    z-index: 14 !important;
  }
}

.p-dropdown-panel ul p-dropdownitem:nth-child(7) {
  li[aria-label="1000000000"] span::before {
    content: "All";
    visibility: visible;
  }
  li[aria-label="1000000000"] span::after {
    content: "";
  }
  li[aria-label="1000000000"] span {
    visibility: hidden;
  }

}

.ag-theme-balham {
  .decimal {
    justify-content: end !important;
  }
}

.p-dropdown-panel{
    .p-dropdown-items {
        .p-dropdown-item{
            white-space: unset;
        }
    }
}

.p-sidebar {
    .p-sidebar-content{
        flex-grow: 1;
    }
    app-nz-dropdown{
        p-overlay{
            .p-component{
                margin-left: -1px;
            }
        }
    }
}

.p-fileupload {
  .p-fileupload-buttonbar{
    padding: 0.27rem !important;
  }

}
formly-field .p-field small.p-error{
    position: relative !important;
}

app-nz-upload{
    .p-fileupload{
        .p-fileupload-content{
            padding: 20px 20px 5px !important;
            border-radius: 6px;
        }
        .desc{
            border: 1px dashed #4C97E4;
            border-radius: 4px;
            margin-bottom: 16px;
            padding: 25px;
            h4{
                font-size: 18px;
                margin-bottom: 10px;
            }
        }
        .p-fileupload-files{
            display: none;
        }

        .p-fileupload-buttonbar{
            padding: 0px !important;
            line-height: 1;
            border: none;
            background: none;
            height: 0;
            text-align: center;
            .p-fileupload-choose{
                width: 143px;
                margin-bottom: -54px !important;
                position: relative;
                z-index: 8;
                background: transparent;
                margin-right: 0px !important;
                height: 33px;
                padding: 0px !important;
                margin: 0px;
                min-width: 0px;
                opacity: 0;
            }
        }
        .p-progressbar{
            display: none;
        }
        .p-fileupload-highlight{
            .desc{
                background: #0a58ca !important;
                h4,p{
                    color: #fff;
                }
            }
        }
        ul{
            li{
                display: flex;
                gap: 12px;
                align-items: center;
                border: 1px solid #4C97E4;
                border-radius: 2px;
                padding: 10px 16px;
                font-size: 12px;
                line-height: 16px;
                color: #465373;
                font-family: "SFProTextRegular";
                margin-bottom: 10px;
                position: relative;
                .p-image{
                    &>img{
                        max-height: 23px;
                        width: auto;
                        image-rendering: pixelated;
                    }
                }
                .pi-download{
                    position: absolute;
                    top: 15px;
                    right: 55px;
                    cursor: pointer;
                }
                .pi-trash{
                    position: absolute;
                    top: 15px;
                     right: 15px;
                     cursor: pointer;
                }
            }
        }
    }
}

.aggrid-no-data{
    font-size: 16px;
}
ag-grid-angular .ag-root-wrapper .ag-root .ag-row-focus .ag-cell{
  &.bg-orange-400,
  &.bg-orange-upload{
    background: #f79530 !important;
  }
  &.bg-red-500 {
    background: var(--red-500) !important;
  }
}
.bg-orange-upload{
  background: #f79530 !important;
  text-align: center;
  .p-fileupload{
    margin-top: 7px;
  }
}
app-upload-render {
  .p-fileupload-choose {
    padding: 1px 14px;
    background-color: var(--surface-500);
    color: var(--surface-0);
    border: none;
    &:hover, &:active{
      background-color: var(--surface-700) !important;
      color: var(--surface-0) !important;
    }
  }
}

.form-dialog {
  .card-header {
    font-size: 20px ;
    padding-bottom: 30px;
  }
}
app-list-grid-angular{
  .ag-theme-balham {
    .ag-ltr {
      .ag-row-group-leaf-indent{
        margin-left: 0px;
      }
    }
  }
}

app-nz-dialog{
    .field{
        gap: 0px;
        button {
            border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
            .pi{
                margin-right: 0px;
            }
        }
    }
}

p-sidebar {
  .p-overlaypanel{
    .p-field {
      p-dropdown {
        width: 100%;
      }
    }
  }
}

app-nz-dialog {
  .p-button-icon-only {
    background-color: var(--surface-500);
  }
}

.detail-overlay {
    padding-top: 25px;
  &.p-overlaypanel {
    z-index: 1000 !important;
  }
  .p-overlaypanel-close{
    top: 0px;
    right: 0px;
    background: none;
    color: #ccc !important;
    &:hover{
        background: none !important;
    }
    .p-overlaypanel-close-icon{
        margin-right: 0px;
    }
  }
}



.grid-fixed{
    .main-grid{
        height: 100vh;
        position: absolute;
        top: 0px;
        width: 100%;
        background: #fff;
    }
    // .bread-crumb{
    //     display: none;
    // }
    // .import-content{
    //     padding-bottom: 0px !important;
    // }
    .wrap-content-import{
        height: 100vh;
        position: fixed;
        top: 0px;
        width: 100%;
        background: #fff;
        z-index: 99999999999999;
    }
    .bread-filter{
        border-bottom: 3px solid #ccc;
        margin-bottom: 0px
    }
}
hr{
    margin: 25px 0px;
    border-top: 1px solid #e7e7e7;
    border-bottom: none;
}
.avatar-list{
  position: relative;
  .name-info{
    display: inline-block;
  }
  .img-info,
  .name-info{
    position: relative;
    z-index: 3;
  }
  label{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &>div{
    position: relative;
    p-checkbox{
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 2;
    }
  }
}

.p-datepicker-timeonly{
    .p-timepicker{
        .p-minute-picker,
        .p-hour-picker{
            button{
                .pi{
                    margin-right: 0px;
                }
            }
        }
    }
}
.dropdown-over{
    max-width: 510px;
}
.ag-cell.input-edit-cell{
  height: 100%;
}

editor-cell{
  input{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
  }
}


.btn-white-image{
  background: #fff !important;
  border: 1px solid #ccc !important;
  color: #000 !important;
  img{
    max-width: 30px;
    margin-right: 8px
  }
}

.p-overlaypanel{
    z-index: 10 !important;
}

p-confirmdialog{
    .confirm-top-zindex{
        z-index: 99999999999 !important;
    }
}

.search-em-in{
    &.p-dialog{
        background: #002560;
        width: 600px;
        transition: all 0.3s ease;
        &.search-em-result{
            width: 1200px;
            transition: all 0.3s ease;
        }
        .p-image{
            display: block;
            padding-top: 100%;
            position: relative;
            margin-bottom: 10px;
        }
        img{
            object-fit: cover;
            position: absolute;
            top: 0px;
            width: 100%;
            height: 100%;
            left: 0px;
        }
        .p-dialog-content{
            padding: 0px !important;
            background: none;
            .search-in{
                display: flex;
                align-items: center;
                background: #002560;
                padding-left: 15px;
                padding-right: 15px;
                .search-group{
                    width: 100%;
                    margin-top: 10px;
                }
                &.res{
                    min-height: 0px;
                }
                .p-autocomplete{
                    input{
                        padding-left: 31px;
                    }
                }
                .col-9{
                    position: relative;
                }
                .pi-search{
                    right: 8px;
                    position: absolute;
                    top: 10px;
                    color: #ccc;
                    z-index: 3;
                    cursor: pointer;
                }
            }
            .search-res{
                padding-left: 10px;
                padding-right: 10px;
                background: #fff;
                margin: auto;
                margin-bottom: 10px;
                border-radius: 4px;
                height: 100%;
            }
        }
        .p-dialog-footer{
            background: #002560;
            padding-top: 0px;
            padding-right: 15px;
        }
        .p-dialog-header{
            background: #002560;
        color: #ffffff;
        border-bottom: none !important;
        box-shadow: none;
        font-weight: 500;
        }
        .search-group{
            background: #fff;
        }
        .number-res{
            position: absolute;
            right: 40px;
            top: 12px;
            color: #979797;
        }
    }

}

.bread-crumb{
  p-selectbutton{
    .p-button{
      height: 34px;
      width: 34px;
      padding: 0px;
      line-height: 34px;
      text-align: center;
      justify-content: center;
      &:first-child{
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:last-child{
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &:active,
      &.p-highlight{
        background: #0a58ca !important;
        color: #fff !important;
        &:hover{
          color: #fff !important;
          background: #0a58ca !important;
        }
      }
    }
  }
}

.fullheight100 {
  .p-tabview-nav {
    height: calc(100ch - 8px) !important;
  }
}

.bread-crumb{
    &>.flex{
        align-items: center;
    }
}
.breadcrumb{
    h3{
        display: none;
    }
}
.button-grid {
    .p-menuitem:first-child {
        display: none;
    }
    .p-button-label{
        border-right: 1px solid rgba(189, 196, 216, 1);
    }
}
.button-grid{
    padding: 0 !important;
    box-shadow: none !important;
    width: 180px !important;
    ul{
        padding: 0 !important;
        .p-menuitem{
            .p-menuitem-text{
                color: rgba(33, 38, 51, 1);
                font-size: 14px;
            }
        }
    }
}
.ag-theme-balham {
    .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell){
        border-left: none;
    }
}
.bread-crumb{
    .fields.search{
        gap: 8px;
    }
}

.option-topbar{
    top: 40.5px !important;
    right: 16px !important;
    z-index: 999 !important;
    box-sizing: border-box;
    padding-bottom: 0px !important;
    padding: 24px 20px 40px 20px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    max-height: 550px;
    overflow-y: auto;
    max-width: 400px;
    left: auto !important;
    .p-overlaypanel-content{
        &>.grid{
            margin: 0px;
        }
    }
    .btn-white-image{
        border: none !important;
        display: block;
        height: auto !important;
        width: 100%;
        padding: 0px !important;
        background: none !important;
        .p-button-label{
            display: block;
        }
        img{
            margin-right: 0;
        }
    }
    .profile .name,
    .btn-white-image .p-button-label,
    .label{
        color: rgba(32, 32, 32, 1);
        font-family: 'SFProTextMedium';
        font-size: 16px;
        font-weight: 500;
        line-height: 32px;
    }
    a{
        text-decoration: none !important;
    }
    .grid{
        grid-template-columns: 1fr 1fr 1fr;
        display: grid;
        gap: 5px;
        margin: 0px;
    }
    .item{
        text-align: center;
        margin-bottom: 12px;
        border-radius: 12px;
        transition: all 0.3s;
        &:hover{
            background: rgba(248, 249, 250, 1);
            transition: all 0.3s;
            cursor: pointer;
        }
        .in{
            padding: 8px;
            width: 116px;
            height: 94px;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon{
                display: block;
                margin-bottom: 4px;
                i{
                    &.uni{
                        &:after{
                          width: 37px;
                          height: 41px;
                        }
                    }
                }
            }
        }
        .p-avatar,
        .btn-white-image img,
        .noti svg,
        .uni-icon{
            width: 64px !important;
            height: 64px !important;
            max-width: 100%;
            margin-bottom: 8px;
            object-fit: contain;
        }
        .p-avatar{
            margin-left: auto;
            margin-right: auto;
            display: block;
        }
    }
}
.no-filter{
    svg{
        margin-left: -32px !important;
    }
}
.btn-setting-icon{
    background: #c7c7c7 !important;
}
app-chi-tiet-cai-dat-quyen{
    .p-breadcrumb{
        margin-bottom: 0px;
    }
    .breadcrumb{
        min-height: 34px;
        line-height: 34px;
    }
    .are-tab-btns{
        position: absolute;
        right: 0px;
        top: 5px;
        z-index: 9;
        .btn-setting{
            background: #c7c7c7;
        }
    }
    .field {
        &.avatar{
            position: relative;
            min-width: 100px;
            .p-image{
                img{
                    width: auto;
                    height: auto;
                }
            }
            .upload-button{
                .pi-camera{
                    top: 100%;
                    right: auto;
                    left: 0px;
                }
            }
        }
    }
    ag-grid-angular {
        .ag-root-wrapper {
            .ag-root {
                .ag-row {
                    .ag-cell{
                        line-height: 36px;
                        font-size: 15px;
                        .ag-cell-expandable{
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
    app-tab-danh-sach-nguoi-dung,
    app-tab-menu-action-tree,
    app-tab-danh-sach-nguoi-dung{
        .btns{
            top: -53px !important;
        }
    }
}

.p-button-secondary{
    background: #ccc !important;
}
app-edit-detail{
}
app-import-excel{
    .p-breadcrumb{
        margin-bottom: 0px;
    }
}

.go-to-home{
    img{
        max-width: 125px;
        max-height: 33px;
    }
}

app-chi-tiet-cai-dat-quyen app-tab-menu-action-tree{
    .btns{
        top: -38px !important;
    }
}

.p-tree {
    .p-tree-container {
        .p-treenode {
            .p-treenode-content {
                border-bottom: 1px solid #f1f1f1;
                .p-tree-toggler{
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                    height: auto;
                }
            }
        }
        p-treenode{
            &:last-child{
                .p-treenode-content {
                    border-bottom: none;
                }
            }
        }
    }
}

.menu-action {
  .p-tree {
    .p-tree-container{
      max-height: 630px;
      overflow-y: auto;
    }
  }
}

.p-treenode-children{
    .p-treenode{
        .p-treenode-content{
            &.p-highlight {
                background: none !important;
            }
        }
    }
}


.error-line-remove {
  background-color: rgb(248, 216, 216);
  position: relative;
}
.error-line-remove::before {
  content: '-';
  position: absolute;
  left: 5px;
  color: black;
  line-height: 19.5px;
  color: red;
}

.error-line-add {
  background-color: rgb(226, 248, 216);
  position: relative;
  /* color: green; */
}
.error-line-add::before {
  content: '+';
  position: absolute;
  left: 5px;
  color: black;
  line-height: 19.5px;
  color: green;
}

.field.avatar .p-image img{
    max-width: 100%;
}
.btns-grid-dropdown{
    padding-top: 0px;
    padding-bottom: 0px;
}

//   new design
.main-grid{
    &>.content{
        padding-top: 0px;
    }
    .bread-crumb{
      margin-bottom: 10px;
    }
    .filter{
        width: calc(100% - 26px);
        margin: auto;
        padding: 8px 13px;
        background: #fff;
        position: relative;
    }
  }
  .filter-tab{
    .bread-crumb{
      margin-bottom: 10px;
    }
    .filter{
        width: calc(85% - 33px);
        margin-right: 13px;
        padding: 8px 20px;
        border: none;
        background: #fff;
        margin-bottom: -66px;
        position: relative;
        z-index: 9;
        margin-left: auto;
    }
    p-tabview{
        .tab-ver{
            &>.p-tabview-panels{
                padding-top: 54px !important;
                padding-right: 0px !important;
            }
        }
    }
  }
  app-import-excel{
    .bread-crumb {
        padding: 21px 20px;
    }
  }
.advance-seach{
    .header-filter-search{
        margin-bottom: 10px;
      h3{
        font-weight: 600;
        margin-bottom: 15px;
        margin-top: 10px;
        padding-left: 10px;

      }
    }
}

.flag-img{
  width: 18px;
  height: 18px;
  object-fit: contain;
  line-height: 1;
  img{
    width: 100%;
    height: auto;
  }
}
